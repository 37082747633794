"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardName_1 = require("@/common/cards/CardName");
const imageOnlyLogos = new Map([
    [CardName_1.CardName.NANOTECH_INDUSTRIES, 'card-nanotech-industries-logo'],
    [CardName_1.CardName.TEMPEST_CONSULTANCY, 'card-tempest-consultancy-logo'],
    [CardName_1.CardName.THE_DARKSIDE_OF_THE_MOON_SYNDICATE, 'card-the-darkside-of-the-moon-syndicate-logo'],
    [CardName_1.CardName.LUNA_HYPERLOOP_CORPORATION, 'card-luna-hyperloop-corporation-logo'],
    [CardName_1.CardName.CRESCENT_RESEARCH_ASSOCIATION, 'card-crescent-research-association-logo'],
    [CardName_1.CardName.LUNA_FIRST_INCORPORATED, 'card-luna-first-incorporated-logo'],
    [CardName_1.CardName.THE_GRAND_LUNA_CAPITAL_GROUP, 'card-the-grand-luna-capital-group-logo'],
    [CardName_1.CardName.INTRAGEN_SANCTUARY_HEADQUARTERS, 'card-intragen-sanctuary-headquarters-logo'],
    [CardName_1.CardName.LUNA_TRADE_FEDERATION, 'card-luna-trade-federation-logo'],
    [CardName_1.CardName.THE_ARCHAIC_FOUNDATION_INSTITUTE, 'card-the-archaic-foundation-institute-logo'],
    [CardName_1.CardName.POLARIS, 'card-polaris-logo'],
    [CardName_1.CardName.RINGCOM, 'card-ringcom-logo'],
    [CardName_1.CardName.CHIMERA, 'card-chimera-logo'],
    [CardName_1.CardName.STEELARIS, 'card-steelaris-logo'],
    [CardName_1.CardName.MARTIAN_INSURANCE_GROUP, 'card-martian-insurance-group-logo'],
    [CardName_1.CardName.ROBIN_HAULINGS, 'card-robin-haulings-logo'],
    [CardName_1.CardName.COLLEGIUM_COPERNICUS, 'card-collegium-copernicus-logo'],
    [CardName_1.CardName.MARS_DIRECT, 'card-mars-direct-logo'],
    [CardName_1.CardName.AMBIENT, 'card-ambient-logo'],
    [CardName_1.CardName.SOYLENT_SEEDLING_SYSTEMS, 'card-soylent-logo'],
    [CardName_1.CardName.BIO_SOL, 'card-bio-sol-logo'],
    [CardName_1.CardName.MIND_SET_MARS, 'card-mind-set-mars-logo'],
    [CardName_1.CardName.ADHAI_HIGH_ORBIT_CONSTRUCTIONS, 'card-adhai-high-orbit-constructions-logo'],
    [CardName_1.CardName.HABITAT_MARTE, 'card-habitat-marte-logo'],
    [CardName_1.CardName.ODYSSEY, 'card-odyssey-logo'],
    [CardName_1.CardName.AURORAI, 'card-aurorai-logo'],
]);
const imageLogosWithNames = new Map([
    [CardName_1.CardName.APHRODITE, 'card-aphrodite-logo'],
    [CardName_1.CardName.ARKLIGHT, 'card-arklight-logo'],
    [CardName_1.CardName.POSEIDON, 'card-poseidon-logo'],
    [CardName_1.CardName.MORNING_STAR_INC, 'card-morning-star-logo'],
    [CardName_1.CardName.PRISTAR, 'card-pristar-logo'],
    [CardName_1.CardName.CREDICOR, 'card-credicor-logo'],
    [CardName_1.CardName.ECOLINE, 'card-ecoline-logo'],
    [CardName_1.CardName.HELION, 'card-helion-logo'],
    [CardName_1.CardName.FACTORUM, 'card-factorum-logo'],
    [CardName_1.CardName.PHOBOLOG, 'card-phobolog-logo'],
    [CardName_1.CardName.POINT_LUNA, 'card-luna-logo'],
    [CardName_1.CardName.POLYPHEMOS, 'card-polyphemos-logo'],
    [CardName_1.CardName.THORGATE, 'card-thorgate-logo'],
    [CardName_1.CardName.LUPINE_TRUST, 'card-lupine-trust-logo'],
    [CardName_1.CardName.VIRON, 'card-viron-logo'],
    [CardName_1.CardName.ARIDOR, 'card-aridor-logo'],
    [CardName_1.CardName.TERACTOR, 'card-teractor-logo'],
    [CardName_1.CardName.MIDAS, 'card-midas-logo'],
    [CardName_1.CardName.MARS_MATHS, 'card-mars-maths-logo'],
]);
exports.default = vue_1.default.extend({
    name: 'CardCorporationLogo',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        CardName() {
            return CardName_1.CardName;
        },
        logoClass() {
            var _a, _b;
            return (_b = (_a = imageOnlyLogos.get(this.title)) !== null && _a !== void 0 ? _a : imageLogosWithNames.get(this.title)) !== null && _b !== void 0 ? _b : '';
        },
        capsTitle() {
            return imageOnlyLogos.has(this.title) ? '' : this.title.toUpperCase();
        },
    },
});
