import { render, staticRenderFns } from "./SelectColony.vue?vue&type=template&id=40bc7268&"
import script from "./SelectColony.vue?vue&type=script&lang=ts&"
export * from "./SelectColony.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports