"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
exports.default = vue_1.default.extend({
    name: 'SelectPayment',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    computed: {
        thisPlayer: function () {
            return this.playerView.thisPlayer;
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
    },
    data() {
        return {
            cost: 0,
            heat: 0,
            megaCredits: 0,
            steel: 0,
            titanium: 0,
            microbes: 0,
            floaters: 0,
            seeds: 0,
            data: 0,
            warning: undefined,
        };
    },
    mounted() {
        vue_1.default.nextTick(() => {
            this.setInitialCost();
            this.megaCredits = this.getMegaCreditsMax();
            this.setDefaultValues();
        });
    },
    methods: Object.assign(Object.assign({}, PaymentWidgetMixin_1.PaymentWidgetMixin.methods), { hasWarning() {
            return this.warning !== undefined;
        },
        setInitialCost() {
            var _a;
            this.cost = (_a = this.playerinput.amount) !== null && _a !== void 0 ? _a : 0;
        },
        canUse(target) {
            switch (target) {
                case 'steel': return this.canUseSteel();
                case 'titanium': return this.canUseTitanium();
                case 'heat': return this.canUseHeat();
                case 'seeds': return this.canUseSeeds();
                case 'data': return this.canUseData();
            }
            return false;
        },
        setDefaultValue(amountCovered, target) {
            if (!this.canUse(target))
                return 0;
            const amount = this.getAmount(target);
            if (amount === 0)
                return 0;
            const cost = this.cost;
            const resourceRate = this.getResourceRate(target);
            let qty = Math.ceil(Math.max(cost - this.getAmount('megaCredits') - amountCovered, 0) / resourceRate);
            qty = Math.min(qty, amount);
            let contributingValue = qty * resourceRate;
            const greedy = target !== 'heat';
            if (greedy === true) {
                while (qty < amount && contributingValue <= cost - resourceRate) {
                    qty++;
                    contributingValue += resourceRate;
                }
            }
            this.$data[target] = qty;
            return contributingValue;
        },
        setDefaultValues(reserveMegacredits = false) {
            const cost = this.cost;
            const megaCredits = this.getAmount('megaCredits');
            const targets = ['seeds', 'data', 'steel', 'titanium', 'heat'];
            let amountCovered = reserveMegacredits ? megaCredits : 0;
            for (const target of targets) {
                amountCovered += this.setDefaultValue(amountCovered, target);
            }
            if (!reserveMegacredits) {
                this.megaCredits = Math.min(megaCredits, Math.max(cost - amountCovered, 0));
            }
        },
        setMaxMCValue() {
            this.setMaxValue('megaCredits');
            this.setDefaultValues(true);
        },
        canAffordWithMcOnly() {
            return this.thisPlayer.megaCredits >= this.cost;
        },
        canUseHeat() {
            return this.playerinput.canUseHeat && this.availableHeat() > 0;
        },
        canUseSteel() {
            return this.playerinput.canUseSteel && this.thisPlayer.steel > 0;
        },
        canUseTitanium() {
            return this.playerinput.canUseTitanium && this.thisPlayer.titanium > 0;
        },
        canUseLunaTradeFederationTitanium() {
            return this.playerinput.canUseLunaTradeFederationTitanium && this.thisPlayer.titanium > 0;
        },
        canUseSeeds() {
            var _a;
            return this.playerinput.canUseSeeds && ((_a = this.playerinput.seeds) !== null && _a !== void 0 ? _a : 0 > 0);
        },
        canUseData() {
            var _a;
            return this.playerinput.canUseData && ((_a = this.playerinput.data) !== null && _a !== void 0 ? _a : 0 > 0);
        },
        saveData() {
            var _a, _b;
            const targets = ['seeds', 'data', 'steel', 'titanium', 'heat', 'megaCredits'];
            const payment = {
                heat: this.heat,
                megaCredits: this.megaCredits,
                steel: this.steel,
                titanium: this.titanium,
                seeds: (_a = this.seeds) !== null && _a !== void 0 ? _a : 0,
                data: (_b = this.data) !== null && _b !== void 0 ? _b : 0,
                microbes: 0,
                floaters: 0,
                science: 0,
            };
            let totalSpent = 0;
            for (const target of targets) {
                if (payment[target] > this.getAmount(target)) {
                    this.warning = `You do not have enough ${target}`;
                    return;
                }
                totalSpent += payment[target] * this.getResourceRate(target);
            }
            const requiredAmt = this.playerinput.amount || 0;
            if (requiredAmt > 0 && totalSpent < requiredAmt) {
                this.warning = 'Haven\'t spent enough';
                return;
            }
            if (requiredAmt === 0) {
                payment.heat = 0;
                payment.megaCredits = 0;
            }
            if (requiredAmt > 0 && totalSpent > requiredAmt) {
                const diff = totalSpent - requiredAmt;
                for (const target of targets) {
                    if (payment[target] && diff >= this.getResourceRate(target)) {
                        this.warning = `You cannot overspend ${target}`;
                        return;
                    }
                }
            }
            const showAlert = (0, PreferencesManager_1.getPreferences)().show_alerts;
            if (requiredAmt > 0 && totalSpent > requiredAmt && showAlert) {
                const diff = totalSpent - requiredAmt;
                if (!confirm('Warning: You are overpaying by ' + diff + ' M€')) {
                    this.warning = 'Please adjust payment amount';
                    return;
                }
            }
            this.onsave({ type: 'payment', payment: payment });
        } }),
});
