"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const spaces_1 = require("@/common/boards/spaces");
exports.default = vue_1.default.extend({
    name: 'GoToMap',
    props: {
        playerinput: {
            type: Object,
        },
    },
    methods: {
        onclick(event) {
            var _a, _b;
            const id = (0, spaces_1.isMarsSpace)((_b = (_a = this.playerinput.availableSpaces) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '00') ? 'shortkey-board' : 'shortkey-moonBoard';
            const el = document.getElementById(id);
            if (el) {
                event.preventDefault();
                el.scrollIntoView({ block: 'center', inline: 'center', behavior: 'auto' });
            }
        },
    },
});
