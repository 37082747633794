"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const constants = require("@/common/constants");
const paths = require("@/common/app/paths");
const Board_vue_1 = require("@/client/components/Board.vue");
const MoonBoard_vue_1 = require("@/client/components/moon/MoonBoard.vue");
const PlanetaryTracks_vue_1 = require("@/client/components/pathfinders/PlanetaryTracks.vue");
const LogPanel_vue_1 = require("@/client/components/LogPanel.vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const VictoryPointChart_vue_1 = require("@/client/components/gameend/VictoryPointChart.vue");
const utils_1 = require("@/common/utils/utils");
const Timer_1 = require("@/common/Timer");
const CardType_1 = require("@/common/cards/CardType");
const ClientCardManifest_1 = require("../cards/ClientCardManifest");
function getViewModel(playerView, spectator) {
    if (playerView !== undefined)
        return playerView;
    if (spectator !== undefined)
        return spectator;
    throw new Error('Neither playerView nor spectator are defined');
}
exports.default = vue_1.default.extend({
    name: 'game-end',
    props: {
        playerView: {
            type: Object,
        },
        spectator: {
            type: Object,
        },
    },
    computed: {
        viewModel() {
            return getViewModel(this.playerView, this.spectator);
        },
        game() {
            return getViewModel(this.playerView, this.spectator).game;
        },
        players() {
            return getViewModel(this.playerView, this.spectator).players;
        },
        color() {
            if (this.playerView !== undefined)
                return this.playerView.thisPlayer.color;
            if (this.spectator !== undefined)
                return this.spectator.color;
            throw new Error('Neither playerView nor spectator are defined');
        },
        downloadLogUrl() {
            var _a, _b;
            const id = ((_a = this.playerView) === null || _a === void 0 ? void 0 : _a.id) || ((_b = this.spectator) === null || _b === void 0 ? void 0 : _b.id);
            if (id === undefined) {
                return undefined;
            }
            return `${paths.API_GAME_LOGS}?id=${id}&full=true`;
        },
    },
    data() {
        return {
            constants,
        };
    },
    components: {
        'board': Board_vue_1.default,
        'log-panel': LogPanel_vue_1.default,
        AppButton: AppButton_vue_1.default,
        MoonBoard: MoonBoard_vue_1.default,
        PlanetaryTracks: PlanetaryTracks_vue_1.default,
        VictoryPointChart: VictoryPointChart_vue_1.default,
    },
    methods: {
        getEndGamePlayerRowColorClass(color) {
            return (0, utils_1.playerColorClass)(color.toLowerCase(), 'bg_transparent');
        },
        getTimer(p) {
            return Timer_1.Timer.toString(p.timer);
        },
        getSortedPlayers() {
            const copy = [...this.viewModel.players];
            copy.sort(function (a, b) {
                if (a.victoryPointsBreakdown.total < b.victoryPointsBreakdown.total)
                    return -1;
                if (a.victoryPointsBreakdown.total > b.victoryPointsBreakdown.total)
                    return 1;
                if (a.megaCredits < b.megaCredits)
                    return -1;
                if (a.megaCredits > b.megaCredits)
                    return 1;
                return 0;
            });
            return copy.reverse();
        },
        getWinners() {
            const sortedPlayers = this.getSortedPlayers();
            const firstWinner = sortedPlayers[0];
            const winners = [firstWinner];
            for (let i = 1; i < sortedPlayers.length; i++) {
                if (sortedPlayers[i].victoryPointsBreakdown.total === firstWinner.victoryPointsBreakdown.total &&
                    sortedPlayers[i].megaCredits === firstWinner.megaCredits) {
                    winners.push(sortedPlayers[i]);
                }
            }
            return winners;
        },
        isSoloGame() {
            return this.players.length === 1;
        },
        getCorporationName(p) {
            const cards = p.tableau;
            const corporationCards = cards
                .filter((card) => { var _a; return ((_a = (0, ClientCardManifest_1.getCard)(card.name)) === null || _a === void 0 ? void 0 : _a.type) === CardType_1.CardType.CORPORATION; })
                .map((card) => card.name);
            return corporationCards.length === 0 ? [''] : corporationCards;
        },
    },
});
