"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const RequirementType_1 = require("@/common/cards/RequirementType");
const utils_1 = require("@/common/utils/utils");
const CardParty_vue_1 = require("@/client/components/card/CardParty.vue");
const PartyName_1 = require("@/common/turmoil/PartyName");
exports.default = vue_1.default.extend({
    name: 'CardRequirementComponent',
    props: {
        requirement: {
            type: Object,
            required: true,
        },
    },
    components: {
        CardParty: CardParty_vue_1.default,
    },
    methods: {
        amount() {
            switch (this.requirement.type) {
                case RequirementType_1.RequirementType.TEMPERATURE:
                case RequirementType_1.RequirementType.OXYGEN:
                case RequirementType_1.RequirementType.VENUS:
                case RequirementType_1.RequirementType.HABITAT_RATE:
                case RequirementType_1.RequirementType.MINING_RATE:
                case RequirementType_1.RequirementType.LOGISTIC_RATE:
                    return this.requirement.amount;
            }
            if (this.requirement.isMax) {
                return this.requirement.amount;
            }
            if (this.requirement.amount !== 1) {
                return this.requirement.amount;
            }
            return '';
        },
        suffix() {
            switch (this.requirement.type) {
                case RequirementType_1.RequirementType.OXYGEN:
                case RequirementType_1.RequirementType.VENUS:
                    return '%';
                case RequirementType_1.RequirementType.TEMPERATURE:
                    return '°C';
            }
            return '';
        },
        isAny() {
            return this.requirement.isAny ? 'red-outline' : '';
        },
        getComponentClasses() {
            const classes = this.getComponentClassArray();
            if (this.requirement.isAny) {
                classes.push('red-outline');
            }
            return (0, utils_1.generateClassString)(classes);
        },
        getComponentClassArray() {
            switch (this.requirement.type) {
                case RequirementType_1.RequirementType.OXYGEN:
                    return ['card-global-requirement', 'card-oxygen--req'];
                case RequirementType_1.RequirementType.TEMPERATURE:
                    return ['card-global-requirement', 'card-temperature--req'];
                case RequirementType_1.RequirementType.OCEANS:
                    return ['card-global-requirement', 'card-ocean--req'];
                case RequirementType_1.RequirementType.VENUS:
                    return ['card-global-requirement', 'card-venus--req'];
                case RequirementType_1.RequirementType.TR:
                    return ['card-tile', 'card-tr', 'card-tr--req'];
                case RequirementType_1.RequirementType.RESOURCE_TYPES:
                    return ['card-resource', 'card-resource-wild'];
                case RequirementType_1.RequirementType.GREENERIES:
                    return ['card-tile', 'greenery-tile--M', 'tile--req'];
                case RequirementType_1.RequirementType.CITIES:
                    return ['card-tile', 'city-tile--M', 'tile--req'];
                case RequirementType_1.RequirementType.COLONIES:
                    return ['card-resource-colony', 'card-resource-colony--req'];
                case RequirementType_1.RequirementType.FLOATERS:
                    return ['card-resource-tag--S', 'card-tag-floater'];
                case RequirementType_1.RequirementType.CHAIRMAN:
                    return ['card-chairman--req'];
                case RequirementType_1.RequirementType.PARTY_LEADERS:
                    return ['card-party-leader--req'];
                case RequirementType_1.RequirementType.TAG:
                    const tagRequirement = this.requirement;
                    return ['card-resource-tag--S', 'card-tag-' + tagRequirement.tag];
                case RequirementType_1.RequirementType.HABITAT_RATE:
                    return ['card-colony-rate', 'card-colony-rate--req'];
                case RequirementType_1.RequirementType.MINING_RATE:
                    return ['card-mining-rate', 'card-mining-rate--req'];
                case RequirementType_1.RequirementType.LOGISTIC_RATE:
                    return ['card-logistics-rate', 'card-logistics-rate--req'];
                case RequirementType_1.RequirementType.HABITAT_TILES:
                    return ['card-tile-lunar-habitat--S', 'tile--req'];
                case RequirementType_1.RequirementType.MINING_TILES:
                    return ['card-tile-lunar-mine--S', 'tile--req'];
                case RequirementType_1.RequirementType.ROAD_TILES:
                    return ['card-tile-lunar-road--S', 'tile--req'];
                case RequirementType_1.RequirementType.PRODUCTION:
                case RequirementType_1.RequirementType.REMOVED_PLANTS:
                    break;
            }
            return [];
        },
        getParty() {
            if (this.requirement.type === RequirementType_1.RequirementType.PARTY) {
                return this.requirement.party;
            }
            else {
                return PartyName_1.PartyName.GREENS;
            }
        },
        getProductionClass() {
            if (this.requirement.type === RequirementType_1.RequirementType.PRODUCTION) {
                const resource = this.requirement.resource;
                return `card-resource card-resource-${resource}`;
            }
            else {
                return '';
            }
        },
    },
    computed: {
        RequirementType() {
            return RequirementType_1.RequirementType;
        },
        isRepeated() {
            switch (this.requirement.type) {
                case RequirementType_1.RequirementType.OXYGEN:
                case RequirementType_1.RequirementType.TEMPERATURE:
                case RequirementType_1.RequirementType.VENUS:
                case RequirementType_1.RequirementType.CHAIRMAN:
                case RequirementType_1.RequirementType.PARTY:
                case RequirementType_1.RequirementType.REMOVED_PLANTS:
                    return false;
            }
            return this.requirement.amount < 4;
        },
        repeats() {
            if (!this.isRepeated || this.requirement.amount === undefined) {
                return [1];
            }
            return (0, utils_1.range)(this.requirement.amount);
        },
    },
});
